import React, { PureComponent } from 'react';
import { Icon } from 'antd';

import { PlaybacksText, Wrapper } from './styles';

interface IProps {
  totalPlaybacks?: String;
}

class PlaybacksTag extends PureComponent<IProps> {
  public render() {
    const { totalPlaybacks } = this.props;

    return (
      <Wrapper>
        <Icon
          type="play-circle"
          theme="outlined"
          style={{ color: '#0099D8' }}
        />
        <PlaybacksText>{totalPlaybacks}</PlaybacksText>
      </Wrapper>
    );
  }
}
export default PlaybacksTag;
