export default {
  error: 'Error',
  success: ' Success',
  'editor.empty': 'Empty field',
  'editor.loader': 'Loading creative...',
  'editor.info.title': 'Creative editor',
  'editor.info.emergency.title': 'Emergency alert edition',
  'editor.info.field.creativity': 'Creative name',
  'editor.info.field.slide': 'Slide name',
  'editor.info.placeholer.creativity': 'Insert the creative name',
  'editor.info.placeholer.slide': 'Insert the slide name',
  'editor.info.placeholer.element.text': 'Insert content',
  'editor.info.placeholer.element.image': 'Insert url',
  'editor.info.locations.title': 'Locations',
  'editor.info.locations.playersScreenTitle': '{number} player selected',
  'editor.info.locations.groupsScreenTitle': '{number} group selected',
  'editor.info.locations.seeTitle': 'See locations',
  'editor.info.locations.allLocationsTitle': 'All Locations',
  'editor.info.locations.specificLocationsTitle': 'Specific locations',
  'editor.info.screen.buttonSelectScreen': 'Specific screen...',
  'editor.info.screen.titleActivity': 'Date and time',
  'editor.info.elements.sectionTitle': 'Content',

  'editor.error.uploadImage.InvalidFileDimensions':
    'Image dimensions do not match original',
  'editor.error.uploadImage.InvalidFileExtension':
    'Image extension should be jpg, jpeg, png or svg',
  'editor.error.uploadImage.default': 'Image upload failed',
  'editor.error.uploadVideo.wrongDimensions':
    'Video dimensions do not match original',
  'editor.error.uploadVideo.InvalidFileExtension':
    'Video extension should be mp4, mov, avi, m4a, 3gp',
  'editor.error.uploadVideo.default': 'Video upload failed',

  'screenSelection.title': 'Screen Selection',
  'screenSelection.phraseSubtitle':
    ' All screens included later in selected groups will be selected automatically',
  'screenSelection.tabs.option1': 'Select by group',
  'screenSelection.tabs.option2': 'Select by screens',
  'dashboard.bygroup': 'By Group',
  'dashboard.next': 'Current',
  'dashboard.current': 'Next',

  'modal.footer.buttonOk': 'Done',

  'screenSelection.panels.button.selectAll': 'Select all',
  'screenSelection.panels.button.addSelect': 'add to selection',
  'screenSelection.panels.button.removeSelection': 'remove from selection',
  'screenSelection.panels.info.noItemsSelects': `No groups selected. You creative creative title won't appear on any screen`,

  'creativeList.byStatus': 'Show',
  'creativeList.nocreatives': 'No creatives found',
  'creativeList.selectTemplate':
    'Select the basis template for your new creative',
  'creativeList.filterCreative': 'Filter Creatives',
  'creativeList.tag.showing': 'Showing',
  'creativeList.tag.all': 'all',
  'creativeList.tag.drafts': 'draft',
  'creativeList.tag.published': 'published',
  'creativeList.tag.completed': 'completed',
  'creativeList.sort': 'Sort by',
  'creativeList.sort.date': 'Date creation',
  'creativeList.sort.name': 'Name',
  'creativeList.sort.creation': 'Date creation',
  'creativeList.sort.start': 'Start Date',
  'creativeList.sort.end': 'End Date',

  'route.default': 'CityPortal',
  'route.dashboard': 'Dashboard',
  'route.creative': 'Creative List',
  'route.emergency': 'Emergency alerts',
  'route.login': 'Login',

  'component.upload': 'or',
  'component.uploadButton': 'Click to upload',

  'condition.clear': 'Clear',
  'condition.partly cloudy': 'Partly cloudy',
  'condition.cloudy': 'Cloudy',
  'condition.rain': 'Rain',
  'condition.drizzle': 'Drizzle',
  'condition.hail': 'Hail',
  'condition.thunderstorm': 'Thunderstorm',
  'condition.snow': 'Snow',
  'condition.fog': 'Fog',
  'condition.haze': 'Haze',
  'condition.sand': 'Sand',
  'condition.dust': 'Dust',
  'condition.smoke': 'Smoke',
  'condition.ash': 'Ash',
  'condition.squalls': 'Squalls',
  'condition.unknown': 'Unknown',
  'condition.undefined': 'Unknown',
  'condition.chance': 'Chance',

  'actions.modalPublishTitle': 'Publish creative',
  'actions.modalDeleteTitle': 'Delete creative?',
  'actions.modalDeleteText':
    'Once you have removed the creative, it can not be recovered',
  'actions.modalConfirm': 'Confirm',
  'actions.deleteModalConfirm': 'Delete',
  'actions.modalCancel': 'Cancel',
  'actions.creative.modalText':
    'Aufmerksamkeit! Möchten Sie es veröffentlichen?',
  'actions.modalText': 'Are you sure you want to publish this creative?',
  'actions.buttons.deactivate': 'Deactivate',
  'actions.buttons.save': 'Save',
  'actions.buttons.publish': 'Publish',
  'actions.buttons.validate&publish': 'Validate & Publish',
  'actions.buttons.requestValidation': 'Request validation',
  'actions.buttons.delete': 'Delete',
  'actions.buttons.unpublish': 'Unpublish',
  'actions.buttons.play': 'Play',
  'actions.buttons.reset': 'Reset',
  'actions.button.send': 'Send',
  'actions.buttons.popOver.isDefault':
    'The creative can not be deleter because it is set as default',
  'actions.buttons.denyComment': 'Deny and comment',
  'actions.buttons.approvePublish': 'Approve and publish',
  'editor.visibility.from': 'Select date range',
  'editor.visibility.dateRange': 'Select date range',
  'editor.visibility.to': ' to ',
  'select.defaultValue.template': 'New from template',
  'dashboard.phraseWelcome': 'Hello',
  'emergency.emergencyGroup.noElements': 'No emergency alerts',
  'emergency.titleGroups.terrorirsm': 'Terrorism',
  'emergency.titleGroups.natural': 'Natural',
  'emergency.titleGroups.technologic': 'Technologic',
  'emergency.titleGroups.sanitary': 'Sanitary',
  'passwordReset.back': 'Back to login',
  'passwordReset.sendAgain': 'Send again',
  'passwordReset.recover': 'Recover user/password',
  'passwordReset.error.invalidEmail': 'Email incorrect',

  'editor.error.notEmpty.description': 'Field must not be empty',

  'creativeList.placeholder': 'New from template',
  'creativeList.create': 'Add',
  'creativeList.createCreative': 'Add a new Creative ',
  'creativeList.deletePopOver.title': 'The creative can not be deleted if:',
  'creativeList.deletePopOver.text1': '- It is a creative set as default',
  'creativeList.deletePopOver.text2':
    '- It is a programmed or published creative',
  'creativeList.deletePopOver.text3':
    '- It is a creative waiting for validation',
  'creativeList.defaultCreative.message': 'Default creative chosen',
  'creativeList.deleteCreative.message': 'Creative delete',
  'creativeList.edit.tooltip.title': 'Edit creative',
  'creativeList.alreadyDefault.popOver':
    'This creative is already set as default',
  'creativeList.notAdmin.popOver': `You don't have permission to set a creative as default`,
  'creativeList.setDefault.tooltip.title': 'Set as default',
  'creativeList.delete.tooltip.title': 'Delete creative',

  'user.errorlogin': 'Login error',
  'emergency.deactivate': 'Deactivate',
  'emergency.alertActive': 'Emergency alert active',
  'app.all': 'All status',
  'app.reset': 'Reset',
  'dashboard.allGroups': 'All Groups',
  'emergency.publishAlert': 'Publish',
  'emergency.terrorism': 'Terrorism',
  'emergency.natural': 'Natural',
  'emergency.sanitary': 'Sanitary',
  'emergency.technologic': 'Technologic',
  'emergency.noEmergencies': 'No emergencies',

  'All groups': 'All Groups',
  All: 'All status',
  Published: 'Published',
  Draft: 'Draft',
  Completed: 'Completed',
  Name: 'Name',
  Creation: 'Date creation',
  Start: 'Start Date',
  End: 'End Date',
  'Missing required parameter: username':
    'Missing required parameter: username',
  'Missing required parameter: password':
    'Missing required parameter: password',
  'Wrong email or password.': 'Wrong email or password.',

  "We've just sent you an email to reset your password.":
    "We've just sent you an email to reset your password.",

  'editor.uploadImage.success': 'Image successfully uploaded',
  'editor.uploadVideo.success': 'Video téléchargée avec succès',

  ddmmyy: 'DD/MM/YY',

  'weekdayselector.all': 'All week',
  'weekdayselector.specificDays': 'Specific days',
  'weekdayselector.monday': 'Mon',
  'weekdayselector.tuesday': 'Tue',
  'weekdayselector.wednesday': 'Wed',
  'weekdayselector.thursday': 'Thu',
  'weekdayselector.friday': 'Fri',
  'weekdayselector.saturday': 'Sat',
  'weekdayselector.sunday': 'Sun',

  'HourSelector.allHours': 'All hours',
  'HourSelector.specificHours': 'Specific hours',

  'loading.message': 'Processing video, can take up to 2 minutes',

  'tag.draft': 'Draft',
  'tag.pending': 'Pending validation',
  'tag.created': 'Created',
  'tag.completed': 'Completed',
  'tag.ongoing': 'Ongoing',
  'tag.scheduled': 'Scheduled',
};
