import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { defaultThumbnail } from '../../assets';
import { getThumbnailUrl } from '../../utils';
import { ICreativity, Status } from '../../redux/modules/creativities';
import { Card, Icon } from 'antd';
import { StatusTag, PlaybacksTag } from '../../components';

import {
  Img,
  Response,
  TemplateAutor,
  SelectedWrapper,
  CardTitleWrapper,
  CustomCard,
  Wrapper,
} from './styles';

const { REACT_APP_PLAYBACKS_COUNT } = process.env;

interface IProps extends ICreativity {
  selected?: boolean;
  actions?: React.ReactNode[];
  time?: boolean;
  status: Status;
}

class CardStyled extends PureComponent<IProps> {
  public render() {
    const { Meta } = Card;
    const {
      _id,
      name,
      actions,
      selected,
      time,
      endDate,
      startDate,
      totalPlaybacks,
    } = this.props;

    return (
      <CustomCard
        key={`cards_fake_${_id}`}
        hoverable
        cover={
          <Link to={`/editor/${_id}`} key={_id}>
            <Img src={_id ? getThumbnailUrl(_id) : defaultThumbnail} />
          </Link>
        }
        actions={actions}
      >
        <Meta
          style={{ position: 'relative' }}
          title={time ? <CardTitleWrapper>{name}</CardTitleWrapper> : ''}
          description={
            <div>
              {time ? (
                <>
                  {REACT_APP_PLAYBACKS_COUNT && (
                    <PlaybacksTag totalPlaybacks={totalPlaybacks} />
                  )}
                  <TemplateAutor>
                    <FormattedMessage id="cardInfo.detail.start" />
                    <Response>{startDate || '--'}</Response>
                  </TemplateAutor>
                  <TemplateAutor>
                    <FormattedMessage id="cardInfo.detail.end" />
                    <Response>{endDate || '--'}</Response>
                  </TemplateAutor>
                </>
              ) : (
                <>
                  <Wrapper>
                    <StatusTag
                      status={this.props.status}
                      endDate={this.props.endDate}
                      startDate={this.props.startDate}
                    />
                    {selected && (
                      <SelectedWrapper>
                        <Icon
                          type="pushpin"
                          rotate={-45}
                          theme="filled"
                          style={{ color: '#0099D8', height: '100%' }}
                        />
                      </SelectedWrapper>
                    )}
                    {REACT_APP_PLAYBACKS_COUNT && (
                      <PlaybacksTag totalPlaybacks={totalPlaybacks} />
                    )}
                  </Wrapper>
                  <CardTitleWrapper>{name}</CardTitleWrapper>
                </>
              )}
            </div>
          }
        />
      </CustomCard>
    );
  }
}
export default CardStyled;
